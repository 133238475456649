import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

import { hbProxyGeoCityModel } from './hbProxyGeoCity.model';
import { hbProxyPostalCodeModel } from './hbProxyPostalCode.model';
	



 class hbProxyGeoSubCityPropertyNames{
	constructor(){}
	//axd
	GuidSubcity:string = "GuidSubcity";	

				 
				 
	Name:string = "Name";	

				 
				 
	GuidCity:string = "GuidCity";	

				 
				 
	GuidPostalCode:string = "GuidPostalCode";	

				 
				 
		FkhbProxyGeoCity:string = "FkhbProxyGeoCity";
		hbProxyGeoCity:string = "hbProxyGeoCity"; //test
				 
		FkhbProxyPostalCode:string = "FkhbProxyPostalCode";
		hbProxyPostalCode:string = "hbProxyPostalCode"; //test
				 
}

//test
export  class hbProxyGeoSubCityModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
        results.push({
            Name: "hbProject",
            FkPropertyName: "GuidSubCity",
            Label: "hbProject",
            EntityName: "hbProject"
        });
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbProxyGeoSubCityModel.PropertyNames.Name,
    type: 'input',

    templateOptions: {
	
	isDefaultProperty: true,


        label: hbProxyGeoSubCityModel.PropertyNames.Name,
        placeholder: '',
        required: true,
		
		maxLength: 255,
    }
},
{
    key: hbProxyGeoSubCityModel.PropertyNames.GuidCity,
    type: 'select',

    templateOptions: {
	
	

	"parents": "GuidCountry:hbProxyGeoCountry.GuidCountry;GuidState:hbProxyGeoState.GuidState",	
	  //"parents": "GuidProject:KstProject.GuidProject;GuidActivityType:KstActivityType.GuidActivityType",
	
	  "relation": {
			PropertyRelationName: "hbProxyGeoCity",
			EntityModel: hbProxyGeoCityModel,  
			DataValue: hbProxyGeoCityModel.PropertyNames.GuidCity,
            DataText: hbProxyGeoCityModel.PropertyNames.Name 
			},

        label: hbProxyGeoSubCityModel.PropertyNames.GuidCity,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbProxyGeoSubCityModel.PropertyNames.GuidPostalCode,
    type: 'select',

    templateOptions: {
	
	

	
	  "relation": {
			PropertyRelationName: "hbProxyPostalCode",
			EntityModel: hbProxyPostalCodeModel,  
			DataValue: hbProxyPostalCodeModel.PropertyNames.GuidPostalCode,
            DataText: hbProxyPostalCodeModel.PropertyNames.PostalCode 
			},

        label: hbProxyGeoSubCityModel.PropertyNames.GuidPostalCode,
        placeholder: '',
        required: false,
		
    }
},
	];
    }
	Id?:string;

		GuidSubcity:string;	

		 
	Name:string;	

		 
	GuidCity?:string;	

		 
	GuidPostalCode?:string;	

		 
		FkhbProxyGeoCity:string ;

		FkhbProxyGeoCityText:any;

		hbProxyGeoCity:string;
		
			 
		 
		FkhbProxyPostalCode:string ;

		FkhbProxyPostalCodeText:any;

		hbProxyPostalCode:string;
		
			 
		 

	public static _EntitySetName:string =  "hbProxyGeoSubCitySet";
	public static _EntityName:string =  "hbProxyGeoSubCity";
	public static PropertyNames: hbProxyGeoSubCityPropertyNames = new hbProxyGeoSubCityPropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidSubcity,Name,GuidCity,GuidPostalCode";

	public static _DefaultProperty: string= "Name";

	public get getSetName() : string {
		return hbProxyGeoSubCityModel._EntitySetName;
	}
	public get getPropertyNames() : hbProxyGeoSubCityPropertyNames {
		return hbProxyGeoSubCityModel.PropertyNames;
	}
}


	
