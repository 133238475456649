import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

import { hbFileModel } from './hbFile.model';
import { hbServiceModel } from './hbService.model';
	



 class hbServiceFilePropertyNames{
	constructor(){}
	//axd
	GuidServiceFile:string = "GuidServiceFile";	

				 
				 
	GuidFile:string = "GuidFile";	

				 
				 
	GuidService:string = "GuidService";	

				 
				 
	GuidCompany:string = "GuidCompany";	

				 
				 
	CreatedBy:string = "CreatedBy";	

				 
				 
	UpdatedBy:string = "UpdatedBy";	

				 
				 
	IsDeleted:string = "IsDeleted";	

				 
				 
	BizKeyEngine:string = "BizKeyEngine";	

				 
				 
	CreatedDate:string = "CreatedDate";	

				 
				 
	UpdatedDate:string = "UpdatedDate";	

				 
				 
		FkhbFile:string = "FkhbFile";
		hbFile:string = "hbFile"; //test
				 
		FkhbService:string = "FkhbService";
		hbService:string = "hbService"; //test
				 
}

//test
export  class hbServiceFileModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbServiceFileModel.PropertyNames.GuidFile,
    type: 'select',

    templateOptions: {
	
	

	
	  "relation": {
			PropertyRelationName: "hbFile",
			EntityModel: hbFileModel,  
			DataValue: hbFileModel.PropertyNames.GuidFile,
            DataText: hbFileModel.PropertyNames.FileName 
			},

        label: hbServiceFileModel.PropertyNames.GuidFile,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbServiceFileModel.PropertyNames.GuidService,
    type: 'select',

    templateOptions: {
	
	

	"parents": "GuidCategory:hbCategory.GuidCategory;GuidExpert:hbExpert.GuidExpert",	
	  //"parents": "GuidProject:KstProject.GuidProject;GuidActivityType:KstActivityType.GuidActivityType",
	
	  "relation": {
			PropertyRelationName: "hbService",
			EntityModel: hbServiceModel,  
			DataValue: hbServiceModel.PropertyNames.GuidService,
            DataText: hbServiceModel.PropertyNames.BizKeyEngine 
			},

        label: hbServiceFileModel.PropertyNames.GuidService,
        placeholder: '',
        required: false,
		
    }
},
	];
    }
	Id?:string;

		GuidServiceFile:string;	

		 
	GuidFile?:string;	

		 
	GuidService?:string;	

		 
	GuidCompany?:string;	

		 
	CreatedBy?:string;	

		 
	UpdatedBy?:string;	

		 
	IsDeleted?:boolean = false;	

		 
	BizKeyEngine?:string;	

		 
	CreatedDate?:string;	

		 
	UpdatedDate?:string;	

		 
		FkhbFile:string ;

		FkhbFileText:any;

		hbFile:string;
		
			 
		 
		FkhbService:string ;

		FkhbServiceText:any;

		hbService:string;
		
			 
		 

	public static _EntitySetName:string =  "hbServiceFileSet";
	public static _EntityName:string =  "hbServiceFile";
	public static PropertyNames: hbServiceFilePropertyNames = new hbServiceFilePropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidServiceFile,GuidFile,GuidService,GuidCompany,CreatedBy,UpdatedBy,IsDeleted,BizKeyEngine,CreatedDate,UpdatedDate,Bytes";

	public static _DefaultProperty: string= "BizKeyEngine";

	public get getSetName() : string {
		return hbServiceFileModel._EntitySetName;
	}
	public get getPropertyNames() : hbServiceFilePropertyNames {
		return hbServiceFileModel.PropertyNames;
	}
}


	
