import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

	



 class hbEmailTemplatePropertyNames{
	constructor(){}
	//axd
	GuidEmailTemplate:string = "GuidEmailTemplate";	

				 
				 
	Title:string = "Title";	

				 
				 
	TitleKey:string = "TitleKey";	

				 
				 
	Content:string = "Content";	

				 
				 
	GuidCompany:string = "GuidCompany";	

				 
				 
	CreatedBy:string = "CreatedBy";	

				 
				 
	UpdatedBy:string = "UpdatedBy";	

				 
				 
	IsDeleted:string = "IsDeleted";	

				 
				 
	BizKeyEngine:string = "BizKeyEngine";	

				 
				 
	CreatedDate:string = "CreatedDate";	

				 
				 
	UpdatedDate:string = "UpdatedDate";	

				 
				 
}

//test
export  class hbEmailTemplateModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbEmailTemplateModel.PropertyNames.Title,
    type: 'input',

    templateOptions: {
	
	isDefaultProperty: true,


        label: hbEmailTemplateModel.PropertyNames.Title,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbEmailTemplateModel.PropertyNames.TitleKey,
    type: 'input',

    templateOptions: {
	
	


        label: hbEmailTemplateModel.PropertyNames.TitleKey,
        placeholder: '',
        required: false,
		
		maxLength: 30,
    }
},
{
    key: hbEmailTemplateModel.PropertyNames.Content,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbEmailTemplateModel.PropertyNames.Content,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
	];
    }
	Id?:string;

		GuidEmailTemplate:string;	

		 
	Title?:string;	

		 
	TitleKey?:string;	

		 
	Content?:string;	

		 
	GuidCompany?:string;	

		 
	CreatedBy?:string;	

		 
	UpdatedBy?:string;	

		 
	IsDeleted?:boolean = false;	

		 
	BizKeyEngine?:string;	

		 
	CreatedDate?:string;	

		 
	UpdatedDate?:string;	

		 

	public static _EntitySetName:string =  "hbEmailTemplateSet";
	public static _EntityName:string =  "hbEmailTemplate";
	public static PropertyNames: hbEmailTemplatePropertyNames = new hbEmailTemplatePropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidEmailTemplate,Title,TitleKey,Content,GuidCompany,CreatedBy,UpdatedBy,IsDeleted,BizKeyEngine,CreatedDate,UpdatedDate,Bytes";

	public static _DefaultProperty: string= "Title";

	public get getSetName() : string {
		return hbEmailTemplateModel._EntitySetName;
	}
	public get getPropertyNames() : hbEmailTemplatePropertyNames {
		return hbEmailTemplateModel.PropertyNames;
	}
}


	
