import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

	



 class hbProxyPostalCodePropertyNames{
	constructor(){}
	//axd
	GuidPostalCode:string = "GuidPostalCode";	

				 
				 
	PostalCode:string = "PostalCode";	

				 
				 
}

//test
export  class hbProxyPostalCodeModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
        results.push({
            Name: "hbProxyGeoSubCity",
            FkPropertyName: "GuidPostalCode",
            Label: "hbProxyGeoSubCity",
            EntityName: "hbProxyGeoSubCity"
        });
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbProxyPostalCodeModel.PropertyNames.PostalCode,
    type: 'input',

    templateOptions: {
	
	isDefaultProperty: true,


        label: hbProxyPostalCodeModel.PropertyNames.PostalCode,
        placeholder: '',
        required: false,
		
		maxLength: 6,
    }
},
	];
    }
	Id?:string;

		GuidPostalCode:string;	

		 
	PostalCode?:string;	

		 

	public static _EntitySetName:string =  "hbProxyPostalCodeSet";
	public static _EntityName:string =  "hbProxyPostalCode";
	public static PropertyNames: hbProxyPostalCodePropertyNames = new hbProxyPostalCodePropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidPostalCode,PostalCode";

	public static _DefaultProperty: string= "PostalCode";

	public get getSetName() : string {
		return hbProxyPostalCodeModel._EntitySetName;
	}
	public get getPropertyNames() : hbProxyPostalCodePropertyNames {
		return hbProxyPostalCodeModel.PropertyNames;
	}
}


	
