import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

import { hbProjectModel } from './hbProject.model';
import { hbProposalModel } from './hbProposal.model';
	



 class hbProjectProposalPropertyNames{
	constructor(){}
	//axd
	GuidProjectProposal:string = "GuidProjectProposal";	

				 
				 
	GuidProject:string = "GuidProject";	

				 
				 
	GuidProposal:string = "GuidProposal";	

				 
				 
		FkhbProject:string = "FkhbProject";
		hbProject:string = "hbProject"; //test
				 
		FkhbProposal:string = "FkhbProposal";
		hbProposal:string = "hbProposal"; //test
				 
}

//test
export  class hbProjectProposalModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbProjectProposalModel.PropertyNames.GuidProject,
    type: 'select',

    templateOptions: {
	
	

	"parents": "GuidCategory:hbCategory.GuidCategory;GuidProjectStatus:hbProjectStatus.GuidProjectStatus;GuidSelectedProposal:hbProposal.GuidProposal;GuidCity:hbProxyGeoCity.GuidCity;GuidCountry:hbProxyGeoCountry.GuidCountry;GuidState:hbProxyGeoState.GuidState;GuidSubCity:hbProxyGeoCity.GuidCity;GuidSubCity:hbProxyGeoSubCity.GuidSubcity;GuidFile:hbFile.GuidFile;CreatedBy:hbProxyUser.GuidUser",	
	  //"parents": "GuidProject:KstProject.GuidProject;GuidActivityType:KstActivityType.GuidActivityType",
	
	  "relation": {
			PropertyRelationName: "hbProject",
			EntityModel: hbProjectModel,  
			DataValue: hbProjectModel.PropertyNames.GuidProject,
            DataText: hbProjectModel.PropertyNames.Title 
			},

        label: hbProjectProposalModel.PropertyNames.GuidProject,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbProjectProposalModel.PropertyNames.GuidProposal,
    type: 'select',

    templateOptions: {
	
	

	"parents": "GuidBroker:hbBroker.GuidBroker;GuidExpert:hbExpert.GuidExpert;CreatedBy:hbProxyUser.GuidUser;GuidProject:hbProject.GuidProject",	
	  //"parents": "GuidProject:KstProject.GuidProject;GuidActivityType:KstActivityType.GuidActivityType",
	
	  "relation": {
			PropertyRelationName: "hbProposal",
			EntityModel: hbProposalModel,  
			DataValue: hbProposalModel.PropertyNames.GuidProposal,
            DataText: hbProposalModel.PropertyNames.Description 
			},

        label: hbProjectProposalModel.PropertyNames.GuidProposal,
        placeholder: '',
        required: false,
		
    }
},
	];
    }
	Id?:string;

		GuidProjectProposal:string;	

		 
	GuidProject?:string;	

		 
	GuidProposal?:string;	

		 
		FkhbProject:string ;

		FkhbProjectText:any;

		hbProject:string;
		
			 
		 
		FkhbProposal:string ;

		FkhbProposalText:any;

		hbProposal:string;
		
			 
		 

	public static _EntitySetName:string =  "hbProjectProposalSet";
	public static _EntityName:string =  "hbProjectProposal";
	public static PropertyNames: hbProjectProposalPropertyNames = new hbProjectProposalPropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidProjectProposal,GuidProject,GuidProposal";

	public static _DefaultProperty: string= "GuidProjectProposal";

	public get getSetName() : string {
		return hbProjectProposalModel._EntitySetName;
	}
	public get getPropertyNames() : hbProjectProposalPropertyNames {
		return hbProjectProposalModel.PropertyNames;
	}
}


	
