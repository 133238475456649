import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

import { hbCategoryModel } from './hbCategory.model';
import { hbExpertModel } from './hbExpert.model';
	



 class hbServicePropertyNames{
	constructor(){}
	//axd
	GuidService:string = "GuidService";	

				 
				 
	GuidCategory:string = "GuidCategory";	

				 
				 
	GuidExpert:string = "GuidExpert";	

				 
				 
	GuidCompany:string = "GuidCompany";	

				 
				 
	CreatedBy:string = "CreatedBy";	

				 
				 
	UpdatedBy:string = "UpdatedBy";	

				 
				 
	IsDeleted:string = "IsDeleted";	

				 
				 
	BizKeyEngine:string = "BizKeyEngine";	

				 
				 
	CreatedDate:string = "CreatedDate";	

				 
				 
	UpdatedDate:string = "UpdatedDate";	

				 
				 
	Title:string = "Title";	

				 
				 
	Description:string = "Description";	

				 
				 
		FkhbCategory:string = "FkhbCategory";
		hbCategory:string = "hbCategory"; //test
				 
		FkhbExpert:string = "FkhbExpert";
		hbExpert:string = "hbExpert"; //test
				 
}

//test
export  class hbServiceModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
        results.push({
            Name: "hbServiceFiles",
            FkPropertyName: "GuidService",
            Label: "hbServiceFiles",
            EntityName: "hbServiceFile"
        });
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbServiceModel.PropertyNames.GuidCategory,
    type: 'select',

    templateOptions: {
	
	

	"parents": "GuidParent:hbCategory.GuidCategory",	
	  //"parents": "GuidProject:KstProject.GuidProject;GuidActivityType:KstActivityType.GuidActivityType",
	
	  "relation": {
			PropertyRelationName: "hbCategory",
			EntityModel: hbCategoryModel,  
			DataValue: hbCategoryModel.PropertyNames.GuidCategory,
            DataText: hbCategoryModel.PropertyNames.Title 
			},

        label: hbServiceModel.PropertyNames.GuidCategory,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbServiceModel.PropertyNames.GuidExpert,
    type: 'select',

    templateOptions: {
	
	

	"parents": "GuidBroker:hbBroker.GuidBroker;GuidCategory:hbCategory.GuidCategory;GuidUser:hbProxyUser.GuidUser",	
	  //"parents": "GuidProject:KstProject.GuidProject;GuidActivityType:KstActivityType.GuidActivityType",
	
	  "relation": {
			PropertyRelationName: "hbExpert",
			EntityModel: hbExpertModel,  
			DataValue: hbExpertModel.PropertyNames.GuidExpert,
            DataText: hbExpertModel.PropertyNames.BizKeyEngine 
			},

        label: hbServiceModel.PropertyNames.GuidExpert,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbServiceModel.PropertyNames.Title,
    type: 'input',

    templateOptions: {
	
	


        label: hbServiceModel.PropertyNames.Title,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: hbServiceModel.PropertyNames.Description,
    type: 'textarea',

    templateOptions: {
	
	


        label: hbServiceModel.PropertyNames.Description,
        placeholder: '',
        required: false,
		rows: 5,
    }
},
	];
    }
	Id?:string;

		GuidService:string;	

		 
	GuidCategory?:string;	

		 
	GuidExpert?:string;	

		 
	GuidCompany?:string;	

		 
	CreatedBy?:string;	

		 
	UpdatedBy?:string;	

		 
	IsDeleted?:boolean = false;	

		 
	BizKeyEngine?:string;	

		 
	CreatedDate?:string;	

		 
	UpdatedDate?:string;	

		 
	Title?:string;	

		 
	Description?:string;	

		 
		FkhbCategory:string ;

		FkhbCategoryText:any;

		hbCategory:string;
		
			 
		 
		FkhbExpert:string ;

		FkhbExpertText:any;

		hbExpert:string;
		
			 
		 

	public static _EntitySetName:string =  "hbServiceSet";
	public static _EntityName:string =  "hbService";
	public static PropertyNames: hbServicePropertyNames = new hbServicePropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidService,GuidCategory,GuidExpert,GuidCompany,CreatedBy,UpdatedBy,IsDeleted,BizKeyEngine,CreatedDate,UpdatedDate,Bytes,Title,Description";

	public static _DefaultProperty: string= "BizKeyEngine";

	public get getSetName() : string {
		return hbServiceModel._EntitySetName;
	}
	public get getPropertyNames() : hbServicePropertyNames {
		return hbServiceModel.PropertyNames;
	}
}


	
