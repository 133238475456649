import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

	



 class hbProjectStatuPropertyNames{
	constructor(){}
	//axd
	GuidProjectStatus:string = "GuidProjectStatus";	

				 
				 
	Name:string = "Name";	

				 
				 
}

//test
export  class hbProjectStatuModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
        results.push({
            Name: "hbProjects",
            FkPropertyName: "GuidProjectStatus",
            Label: "hbProjects",
            EntityName: "hbProject"
        });
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbProjectStatuModel.PropertyNames.Name,
    type: 'input',

    templateOptions: {
	
	isDefaultProperty: true,


        label: hbProjectStatuModel.PropertyNames.Name,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
	];
    }
	Id?:string;

		GuidProjectStatus:string;	

		 
	Name?:string;	

		 

	public static _EntitySetName:string =  "hbProjectStatus";
	public static _EntityName:string =  "hbProjectStatu";
	public static PropertyNames: hbProjectStatuPropertyNames = new hbProjectStatuPropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidProjectStatus,Name";

	public static _DefaultProperty: string= "Name";

	public get getSetName() : string {
		return hbProjectStatuModel._EntitySetName;
	}
	public get getPropertyNames() : hbProjectStatuPropertyNames {
		return hbProjectStatuModel.PropertyNames;
	}
}


	
