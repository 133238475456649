import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

	



 class unProxyMessageToUserPropertyNames{
	constructor(){}
	//axd
	GuidMessageToUser:string = "GuidMessageToUser";	

				 
				 
	Title:string = "Title";	

				 
				 
	Content:string = "Content";	

				 
				 
	Readed:string = "Readed";	

				 
				 
	GuidUser:string = "GuidUser";	

				 
				 
	UpdatedDate:string = "UpdatedDate";	

				 
				 
}

//test
export  class unProxyMessageToUserModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: unProxyMessageToUserModel.PropertyNames.Title,
    type: 'input',

    templateOptions: {
	
	isDefaultProperty: true,


        label: unProxyMessageToUserModel.PropertyNames.Title,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: unProxyMessageToUserModel.PropertyNames.Content,
    type: 'input',

    templateOptions: {
	
	


        label: unProxyMessageToUserModel.PropertyNames.Content,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: unProxyMessageToUserModel.PropertyNames.Readed,
    type: 'datepicker',

    templateOptions: {
	
	


        label: unProxyMessageToUserModel.PropertyNames.Readed,
        placeholder: '',
        required: false,
		
    }
},
{
    key: unProxyMessageToUserModel.PropertyNames.GuidUser,
    type: 'input',

    templateOptions: {
	
	


        label: unProxyMessageToUserModel.PropertyNames.GuidUser,
        placeholder: '',
        required: false,
		
    }
},
	];
    }
	Id?:string;

		GuidMessageToUser:string;	

		 
	Title?:string;	

		 
	Content?:string;	

		 
	Readed?:string;	

		 
	GuidUser?:string;	

		 
	UpdatedDate?:string;	

		 

	public static _EntitySetName:string =  "unProxyMessageToUserSet";
	public static _EntityName:string =  "unProxyMessageToUser";
	public static PropertyNames: unProxyMessageToUserPropertyNames = new unProxyMessageToUserPropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidMessageToUser,Title,Content,Readed,GuidUser,UpdatedDate";

	public static _DefaultProperty: string= "Title";

	public get getSetName() : string {
		return unProxyMessageToUserModel._EntitySetName;
	}
	public get getPropertyNames() : unProxyMessageToUserPropertyNames {
		return unProxyMessageToUserModel.PropertyNames;
	}
}


	
