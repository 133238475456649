import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

import { hbCategoryModel } from './hbCategory.model';
import { hbExpertModel } from './hbExpert.model';
import { hbProxyUserModel } from './hbProxyUser.model';
	



 class hbExpertCategoryPropertyNames{
	constructor(){}
	//axd
	GuidExpertCategory:string = "GuidExpertCategory";	

				 
				 
	GuidCategory:string = "GuidCategory";	

				 
				 
	GuidUser:string = "GuidUser";	

				 
				 
	GuidExpert:string = "GuidExpert";	

				 
				 
		FkhbCategory:string = "FkhbCategory";
		hbCategory:string = "hbCategory"; //test
				 
		FkhbExpert:string = "FkhbExpert";
		hbExpert:string = "hbExpert"; //test
				 
		FkhbProxyUser:string = "FkhbProxyUser";
		hbProxyUser:string = "hbProxyUser"; //test
				 
}

//test
export  class hbExpertCategoryModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbExpertCategoryModel.PropertyNames.GuidCategory,
    type: 'select',

    templateOptions: {
	
	

	"parents": "GuidParent:hbCategory.GuidCategory",	
	  //"parents": "GuidProject:KstProject.GuidProject;GuidActivityType:KstActivityType.GuidActivityType",
	
	  "relation": {
			PropertyRelationName: "hbCategory",
			EntityModel: hbCategoryModel,  
			DataValue: hbCategoryModel.PropertyNames.GuidCategory,
            DataText: hbCategoryModel.PropertyNames.Title 
			},

        label: hbExpertCategoryModel.PropertyNames.GuidCategory,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbExpertCategoryModel.PropertyNames.GuidUser,
    type: 'select',

    templateOptions: {
	
	

	"parents": "GuidRole:hbProxyRole.GuidRole;GuidFile:hbFile.GuidFile",	
	  //"parents": "GuidProject:KstProject.GuidProject;GuidActivityType:KstActivityType.GuidActivityType",
	
	  "relation": {
			PropertyRelationName: "hbProxyUser",
			EntityModel: hbProxyUserModel,  
			DataValue: hbProxyUserModel.PropertyNames.GuidUser,
            DataText: hbProxyUserModel.PropertyNames.Username 
			},

        label: hbExpertCategoryModel.PropertyNames.GuidUser,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbExpertCategoryModel.PropertyNames.GuidExpert,
    type: 'select',

    templateOptions: {
	
	

	"parents": "GuidBroker:hbBroker.GuidBroker;GuidCategory:hbCategory.GuidCategory;GuidUser:hbProxyUser.GuidUser",	
	  //"parents": "GuidProject:KstProject.GuidProject;GuidActivityType:KstActivityType.GuidActivityType",
	
	  "relation": {
			PropertyRelationName: "hbExpert",
			EntityModel: hbExpertModel,  
			DataValue: hbExpertModel.PropertyNames.GuidExpert,
            DataText: hbExpertModel.PropertyNames.BizKeyEngine 
			},

        label: hbExpertCategoryModel.PropertyNames.GuidExpert,
        placeholder: '',
        required: false,
		
    }
},
	];
    }
	Id?:string;

		GuidExpertCategory:string;	

		 
	GuidCategory?:string;	

		 
	GuidUser?:string;	

		 
	GuidExpert?:string;	

		 
		FkhbCategory:string ;

		FkhbCategoryText:any;

		hbCategory:string;
		
			 
		 
		FkhbExpert:string ;

		FkhbExpertText:any;

		hbExpert:string;
		
			 
		 
		FkhbProxyUser:string ;

		FkhbProxyUserText:any;

		hbProxyUser:string;
		
			 
		 

	public static _EntitySetName:string =  "hbExpertCategorySet";
	public static _EntityName:string =  "hbExpertCategory";
	public static PropertyNames: hbExpertCategoryPropertyNames = new hbExpertCategoryPropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidExpertCategory,GuidCategory,GuidUser,GuidExpert";

	public static _DefaultProperty: string= "GuidExpertCategory";

	public get getSetName() : string {
		return hbExpertCategoryModel._EntitySetName;
	}
	public get getPropertyNames() : hbExpertCategoryPropertyNames {
		return hbExpertCategoryModel.PropertyNames;
	}
}


	
