import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

import { hbFileModel } from './hbFile.model';
import { hbProposalModel } from './hbProposal.model';
	



 class hbProposalFilePropertyNames{
	constructor(){}
	//axd
	GuidPRoposalFile:string = "GuidPRoposalFile";	

				 
				 
	GuidFile:string = "GuidFile";	

				 
				 
	GuidProposal:string = "GuidProposal";	

				 
				 
	GuidCompany:string = "GuidCompany";	

				 
				 
	CreatedBy:string = "CreatedBy";	

				 
				 
	UpdatedBy:string = "UpdatedBy";	

				 
				 
	IsDeleted:string = "IsDeleted";	

				 
				 
	BizKeyEngine:string = "BizKeyEngine";	

				 
				 
	CreatedDate:string = "CreatedDate";	

				 
				 
	UpdatedDate:string = "UpdatedDate";	

				 
				 
		FkhbFile:string = "FkhbFile";
		hbFile:string = "hbFile"; //test
				 
		FkhbProposal:string = "FkhbProposal";
		hbProposal:string = "hbProposal"; //test
				 
}

//test
export  class hbProposalFileModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: hbProposalFileModel.PropertyNames.GuidFile,
    type: 'select',

    templateOptions: {
	
	

	
	  "relation": {
			PropertyRelationName: "hbFile",
			EntityModel: hbFileModel,  
			DataValue: hbFileModel.PropertyNames.GuidFile,
            DataText: hbFileModel.PropertyNames.FileName 
			},

        label: hbProposalFileModel.PropertyNames.GuidFile,
        placeholder: '',
        required: false,
		
    }
},
{
    key: hbProposalFileModel.PropertyNames.GuidProposal,
    type: 'select',

    templateOptions: {
	
	

	"parents": "GuidBroker:hbBroker.GuidBroker;GuidExpert:hbExpert.GuidExpert;CreatedBy:hbProxyUser.GuidUser;GuidProject:hbProject.GuidProject",	
	  //"parents": "GuidProject:KstProject.GuidProject;GuidActivityType:KstActivityType.GuidActivityType",
	
	  "relation": {
			PropertyRelationName: "hbProposal",
			EntityModel: hbProposalModel,  
			DataValue: hbProposalModel.PropertyNames.GuidProposal,
            DataText: hbProposalModel.PropertyNames.Description 
			},

        label: hbProposalFileModel.PropertyNames.GuidProposal,
        placeholder: '',
        required: false,
		
    }
},
	];
    }
	Id?:string;

		GuidPRoposalFile:string;	

		 
	GuidFile?:string;	

		 
	GuidProposal?:string;	

		 
	GuidCompany?:string;	

		 
	CreatedBy?:string;	

		 
	UpdatedBy?:string;	

		 
	IsDeleted?:boolean = false;	

		 
	BizKeyEngine?:string;	

		 
	CreatedDate?:string;	

		 
	UpdatedDate?:string;	

		 
		FkhbFile:string ;

		FkhbFileText:any;

		hbFile:string;
		
			 
		 
		FkhbProposal:string ;

		FkhbProposalText:any;

		hbProposal:string;
		
			 
		 

	public static _EntitySetName:string =  "hbProposalFileSet";
	public static _EntityName:string =  "hbProposalFile";
	public static PropertyNames: hbProposalFilePropertyNames = new hbProposalFilePropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidPRoposalFile,GuidFile,GuidProposal,GuidCompany,CreatedBy,UpdatedBy,IsDeleted,BizKeyEngine,CreatedDate,UpdatedDate,Bytes";

	public static _DefaultProperty: string= "BizKeyEngine";

	public get getSetName() : string {
		return hbProposalFileModel._EntitySetName;
	}
	public get getPropertyNames() : hbProposalFilePropertyNames {
		return hbProposalFileModel.PropertyNames;
	}
}


	
