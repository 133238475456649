import { FormlyFieldConfig } from '@ngx-formly/core';
import { ChildRelation } from '../common/page.model';

	



 class unProxySettingPropertyNames{
	constructor(){}
	//axd
	 SMTPServerId:string = "SMTPServer";	
	 SMTPSSLEnabledId:string = "SMTPSSLEnabled";	
	 SMTPUserId:string = "SMTPUser";	
	 SMTPPasswordId:string = "SMTPPassword";	
	 SMTPFromNameId:string = "SMTPFromName";	
	 SMTPPortId:string = "SMTPPort";	
	 SMTPFromEmailId:string = "SMTPFromEmail";	
	 AzureBlobStorageConnectionId:string = "AzureBlobStorageConnection";	
	 SaveBlobInAzureStorageId:string = "SaveBlobInAzureStorage";	
	 SaveBlobInDataBaseId:string = "SaveBlobInDataBase";	
	 UserNameGeoNamesId:string = "UserNameGeoNames";	
	GuidUnProxySetting:string = "GuidUnProxySetting";	

				 
				 
	SMTPServer:string = "SMTPServer";	

				 
				 
	SMTPSSLEnabled:string = "SMTPSSLEnabled";	

				 
				 
	SMTPUser:string = "SMTPUser";	

				 
				 
	SMTPPassword:string = "SMTPPassword";	

				 
				 
	SMTPFromName:string = "SMTPFromName";	

				 
				 
	SMTPPort:string = "SMTPPort";	

				 
				 
	SMTPFromEmail:string = "SMTPFromEmail";	

				 
				 
	AzureBlobStorageConnection:string = "AzureBlobStorageConnection";	

				 
				 
	SaveBlobInAzureStorage:string = "SaveBlobInAzureStorage";	

				 
				 
	SaveBlobInDataBase:string = "SaveBlobInDataBase";	

				 
				 
	UserNameGeoNames:string = "UserNameGeoNames";	

				 
				 
}

//test
export  class unProxySettingModel{

    public static GetChildren(){
		let results:Array<ChildRelation> = [];
		return results;
	}
public static GetFields():Array<FormlyFieldConfig>{
return [
{
    key: unProxySettingModel.PropertyNames.SMTPServer,
    type: 'input',

    templateOptions: {
	
	isDefaultProperty: true,


        label: unProxySettingModel.PropertyNames.SMTPServer,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: unProxySettingModel.PropertyNames.SMTPSSLEnabled,
    type: 'checkbox',

    templateOptions: {
	 
	indeterminate: false,

	


        label: unProxySettingModel.PropertyNames.SMTPSSLEnabled,
        placeholder: '',
        required: false,
		
    }
},
{
    key: unProxySettingModel.PropertyNames.SMTPUser,
    type: 'input',

    templateOptions: {
	
	


        label: unProxySettingModel.PropertyNames.SMTPUser,
        placeholder: '',
        required: false,
		
		maxLength: 100,
    }
},
{
    key: unProxySettingModel.PropertyNames.SMTPPassword,
    type: 'input',

    templateOptions: {
	
	


        label: unProxySettingModel.PropertyNames.SMTPPassword,
        placeholder: '',
        required: false,
		
		maxLength: 100,
    }
},
{
    key: unProxySettingModel.PropertyNames.SMTPFromName,
    type: 'input',

    templateOptions: {
	
	


        label: unProxySettingModel.PropertyNames.SMTPFromName,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: unProxySettingModel.PropertyNames.SMTPPort,
    type: 'input',

    templateOptions: {
	
	


        label: unProxySettingModel.PropertyNames.SMTPPort,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: unProxySettingModel.PropertyNames.SMTPFromEmail,
    type: 'input',

    templateOptions: {
	
	


        label: unProxySettingModel.PropertyNames.SMTPFromEmail,
        placeholder: '',
        required: false,
		
		maxLength: 255,
    }
},
{
    key: unProxySettingModel.PropertyNames.AzureBlobStorageConnection,
    type: 'input',

    templateOptions: {
	
	


        label: unProxySettingModel.PropertyNames.AzureBlobStorageConnection,
        placeholder: '',
        required: false,
		
		maxLength: 500,
    }
},
{
    key: unProxySettingModel.PropertyNames.SaveBlobInAzureStorage,
    type: 'checkbox',

    templateOptions: {
	 
	indeterminate: false,

	


        label: unProxySettingModel.PropertyNames.SaveBlobInAzureStorage,
        placeholder: '',
        required: false,
		
		maxLength: 500,
    }
},
{
    key: unProxySettingModel.PropertyNames.SaveBlobInDataBase,
    type: 'checkbox',

    templateOptions: {
	 
	indeterminate: false,

	


        label: unProxySettingModel.PropertyNames.SaveBlobInDataBase,
        placeholder: '',
        required: false,
		
		maxLength: 500,
    }
},
{
    key: unProxySettingModel.PropertyNames.UserNameGeoNames,
    type: 'input',

    templateOptions: {
	
	


        label: unProxySettingModel.PropertyNames.UserNameGeoNames,
        placeholder: '',
        required: false,
		
		maxLength: 500,
    }
},
	];
    }
	Id?:string;

		 SMTPServerId: string;
	 SMTPSSLEnabledId: string;
	 SMTPUserId: string;
	 SMTPPasswordId: string;
	 SMTPFromNameId: string;
	 SMTPPortId: string;
	 SMTPFromEmailId: string;
	 AzureBlobStorageConnectionId: string;
	 SaveBlobInAzureStorageId: string;
	 SaveBlobInDataBaseId: string;
	 UserNameGeoNamesId: string;
	GuidUnProxySetting:string;	

		 
	SMTPServer?:string;	

		 
	SMTPSSLEnabled?:boolean = false;	

		 
	SMTPUser?:string;	

		 
	SMTPPassword?:string;	

		 
	SMTPFromName?:string;	

		 
	SMTPPort?:string;	

		 
	SMTPFromEmail?:string;	

		 
	AzureBlobStorageConnection?:string;	

		 
	SaveBlobInAzureStorage?:boolean = false;	

		 
	SaveBlobInDataBase?:boolean = false;	

		 
	UserNameGeoNames?:string;	

		 

	public static _EntitySetName:string =  "unProxySettings";
	public static _EntityName:string =  "unProxySetting";
	public static PropertyNames: unProxySettingPropertyNames = new unProxySettingPropertyNames();
	public static _PropertyNamesForUpdate: string = "GuidUnProxySetting,SMTPServer,SMTPSSLEnabled,SMTPUser,SMTPPassword,SMTPFromName,SMTPPort,SMTPFromEmail,AzureBlobStorageConnection,SaveBlobInAzureStorage,SaveBlobInDataBase,UserNameGeoNames";

	public static _DefaultProperty: string= "SMTPServer";

	public get getSetName() : string {
		return unProxySettingModel._EntitySetName;
	}
	public get getPropertyNames() : unProxySettingPropertyNames {
		return unProxySettingModel.PropertyNames;
	}
}


	
